'use client';

import { ButtonGroup } from '@royalaholddelhaize/design-system-pantry-web/components/button/button-group/button-group';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { FC } from 'react';

import css from './not-found.module.scss';

export const LabsNotFound: FC<{ router: AppRouterInstance }> = ({ router }) => {
    const goHome = () => {
        router.push('/');
    };

    const goBack = () => {
        router.back();
    };

    return (
        <Container className={css.container}>
            <div className={css.content}>
                <Typography variant="heading-1" align="center">
                    Oeps! Je hebt een pagina gevonden, waar wij het bestaan niet
                    van af wisten!
                </Typography>
                <Typography className={css.errorCode}>404</Typography>
                <Bag />
                <ButtonGroup className={css.buttonGroup}>
                    <RegularButton level="secondary" onClick={goBack}>
                        <RegularButtonText>Ga terug</RegularButtonText>
                    </RegularButton>
                    <RegularButton onClick={goHome}>
                        <RegularButtonText>Home</RegularButtonText>
                    </RegularButton>
                </ButtonGroup>
            </div>
        </Container>
    );
};

const Bag: React.FC<React.SVGProps<SVGSVGElement>> = props => (
    <svg
        {...props}
        width="214"
        height="122"
        viewBox="0 0 214 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Lege boodschappen tas</title>
        <path
            d="M0.580078 76.5957L78.6966 110.599L127.405 89.9214L170.598 72.0006L74.1016 50.8632L0.580078 76.5957Z"
            fill="#E8A64D"
        />
        <path
            d="M170.599 72.0006L165.544 52.7011L77.3182 38.1542L73.1826 52.7012L170.599 72.0006Z"
            fill="#BE863C"
        />
        <path
            d="M170.599 28.8068L165.544 52.7013L80.5352 39.3755L84.6707 17.7786L170.599 28.8068Z"
            fill="#E8A64D"
        />
        <path
            d="M43.8754 62.0215C44.4322 60.9112 44.9212 59.5583 45.2738 57.9993C44.4544 57.2418 43.4283 56.5885 42.1928 56.4695C41.3078 56.3835 40.8751 56.6265 39.8993 56.5966C38.9919 56.5695 37.8748 56.3214 36.5983 55.5329C36.2499 55.7938 35.7435 56.2356 35.29 56.8967C38.3016 58.2833 40.8938 60.5069 43.8754 62.0215Z"
            fill="#6E9E23"
        />
        <path
            d="M40.2949 60.9247C39.6309 62.2897 38.986 63.6589 39.4481 65.1296C39.722 66.0054 40.2682 66.5642 40.513 66.8047C55.2798 81.2841 74.3012 83.5319 74.3012 83.5319C82.3279 84.4794 92.6737 85.7013 96.9651 79.8813C97.1345 79.6514 100.229 75.3149 98.6991 70.6433C97.5356 67.0852 94.3443 65.3496 93.568 64.9227C91.5307 63.8137 89.1854 63.5651 84.4805 63.2372C82.4773 63.0987 80.9437 63.0566 79.5857 63.0727C77.4729 63.0955 76.7443 63.2379 75.2183 63.2752C72.4599 63.344 70.3844 63.008 68.5781 62.7152C62.628 61.7465 55.644 59.2898 52.0719 58.0244C52.0719 58.0244 45.5601 55.7575 42.8383 57.497C42.7596 57.5483 42.691 57.5977 42.6385 57.6362C41.477 58.5192 40.7246 60.0467 40.2949 60.9247Z"
            fill="#235B1D"
        />
        <path
            d="M42.0742 58.1432C44.471 58.0513 46.8889 58.2576 49.2731 58.7204C50.4637 58.9573 51.6477 59.2683 52.8053 59.6312C53.9679 59.9932 55.101 60.418 56.221 60.8427C57.339 61.2703 58.443 61.6929 59.5471 62.0893C60.6532 62.4827 61.7596 62.837 62.8782 63.1604C65.1156 63.794 67.4012 64.2727 69.7048 64.5891C70.8556 64.7553 72.0088 64.8664 73.1683 64.9294C73.7469 64.9819 74.3257 64.9953 74.9076 65.0108C75.4864 65.0373 76.0684 65.0267 76.6494 65.0241C78.9782 65.0127 81.3034 64.7936 83.6978 64.5954C84.8895 64.5013 86.1122 64.4195 87.3612 64.4734C88.6011 64.5343 89.8956 64.7021 91.0794 65.2137C91.6596 65.49 92.2297 65.7812 92.7277 66.1719C93.2562 66.5179 93.7067 66.9592 94.1189 67.4182C94.9423 68.3442 95.5617 69.4154 95.9798 70.5376C95.3308 69.5292 94.5647 68.6159 93.6688 67.8807C93.2117 67.5266 92.7576 67.1744 92.2415 66.933C91.7528 66.6447 91.2193 66.4612 90.6937 66.2919C89.6303 65.9712 88.5048 65.9356 87.3706 66.0034C86.2334 66.069 85.0825 66.2389 83.9074 66.4185C81.5723 66.7879 79.1568 67.1334 76.7148 67.2338C74.2758 67.3362 71.8099 67.2327 69.3877 66.8969C66.9636 66.5511 64.5708 66.017 62.2387 65.271C59.9065 64.5249 57.6614 63.5411 55.5058 62.5221C53.3442 61.5121 51.2287 60.5246 48.9827 59.8196C46.7601 59.0738 44.4346 58.5635 42.0742 58.1432Z"
            fill="#6E9E23"
        />
        <path
            d="M40.5938 60.3289C42.8835 60.937 45.1456 61.6442 47.4156 62.3524C49.6571 63.1286 51.8872 63.9538 54.0753 64.9119C55.1689 65.3885 56.2532 65.898 57.3104 66.4023C58.3726 66.9056 59.4018 67.3734 60.4698 67.7714C62.6127 68.5505 64.828 69.1779 67.0807 69.6211C71.5839 70.5106 76.2504 70.8692 80.8488 70.6883C82.0172 70.622 83.2487 70.5724 84.4525 70.6349C85.6632 70.6934 86.8686 70.8181 88.0563 71.0268C90.4296 71.4604 92.7354 72.2322 94.8239 73.3628C92.5414 72.7116 90.2395 72.2698 87.9274 72.166C85.6066 72.0169 83.339 72.1965 81.0202 72.516C76.2225 73.0548 71.3842 72.8458 66.629 71.9074C66.0337 71.7955 65.4456 71.6535 64.8565 71.5066C64.2674 71.3726 63.6824 71.2067 63.1006 71.0297C62.5167 70.8688 61.941 70.6698 61.3694 70.4648C60.7947 70.2709 60.2221 70.0609 59.6598 69.823C58.531 69.3531 57.4258 68.8031 56.3871 68.2329C55.3394 67.6696 54.3218 67.1005 53.2859 66.5584C51.2163 65.4712 49.1142 64.4187 46.9858 63.405C44.8503 62.4083 42.7086 61.4206 40.5938 60.3289Z"
            fill="#6E9E23"
        />
        <path
            d="M39.5894 62.537C41.6187 64.1163 43.6899 65.6018 45.8647 66.9089C48.0111 68.2578 50.2789 69.3182 52.6085 70.307C54.9432 71.2948 57.3066 72.1757 59.7102 73.075C62.1027 73.9712 64.4759 74.8893 66.8612 75.654C69.2373 76.4386 71.6743 76.9852 74.1654 77.5425C76.6335 78.0884 79.0971 78.557 81.5745 78.7339C82.1923 78.7869 82.8104 78.8007 83.4324 78.8217C84.0516 78.8145 84.6737 78.8223 85.2911 78.779C85.9133 78.7608 86.527 78.6844 87.1485 78.622C87.7602 78.5224 88.3828 78.4521 88.9878 78.3043C91.4423 77.8238 93.8309 76.9494 96.1128 75.7929C94.1065 77.3898 91.7711 78.5929 89.2864 79.4151C88.9761 79.523 88.6629 79.6158 88.3448 79.6966C88.0267 79.7773 87.7105 79.8681 87.3914 79.9439C86.7493 80.0752 86.1072 80.2196 85.4533 80.3036C84.1515 80.5019 82.8292 80.5815 81.5126 80.569C78.8753 80.5629 76.2725 80.183 73.7305 79.7135C72.4576 79.4687 71.2149 79.2182 69.9322 78.9231C68.6617 78.6232 67.3945 78.2731 66.1517 77.8742C63.6641 77.0662 61.272 76.1048 58.9032 75.1286C56.5433 74.1585 54.1476 73.1379 51.8402 71.9678C50.683 71.3781 49.5371 70.7656 48.4278 70.0732C47.3175 69.3888 46.2387 68.6385 45.2145 67.8467C43.1783 66.2452 41.2636 64.4906 39.5894 62.537Z"
            fill="#6E9E23"
        />
        <path
            d="M90.3859 74.4583C90.3461 73.9063 89.4207 73.5186 88.3189 73.5924C87.217 73.6663 86.356 74.1737 86.3958 74.7258C86.4355 75.2778 87.361 75.6655 88.4629 75.5916C89.5647 75.5178 90.4257 75.0104 90.3859 74.4583Z"
            fill="#6E9E23"
        />
        <path
            d="M92.3682 69.9279C92.4469 69.5365 91.8775 69.0896 91.0964 68.9297C90.3154 68.7699 89.6184 68.9575 89.5398 69.3489C89.4612 69.7402 90.0307 70.1871 90.8117 70.347C91.5927 70.5069 92.2896 70.3192 92.3682 69.9279Z"
            fill="#6E9E23"
        />
        <path
            d="M92.4259 75.6518C92.2668 75.2844 91.5453 75.2401 90.8144 75.5527C90.0836 75.8653 89.6201 76.4165 89.7792 76.7839C89.9384 77.1512 90.6599 77.1956 91.3907 76.8829C92.1216 76.5703 92.5851 76.0191 92.4259 75.6518Z"
            fill="#6E9E23"
        />
        <path
            d="M48.577 66.2311C48.756 65.8747 48.3236 65.2947 47.6111 64.9357C46.8987 64.5768 46.176 64.5748 45.997 64.9313C45.818 65.2878 46.2504 65.8678 46.9629 66.2267C47.6753 66.5856 48.398 66.5876 48.577 66.2311Z"
            fill="#6E9E23"
        />
        <path
            d="M58.6786 77.2967C58.8576 76.9402 58.4251 76.3602 57.7127 76.0013C57.0002 75.6424 56.2776 75.6404 56.0985 75.9969C55.9195 76.3533 56.352 76.9333 57.0644 77.2923C57.7769 77.6512 58.4996 77.6532 58.6786 77.2967Z"
            fill="#6E9E23"
        />
        <path
            d="M68.9621 68.4777C69.0753 68.095 68.5477 67.5996 67.7836 67.3711C67.0195 67.1427 66.3084 67.2677 66.1952 67.6504C66.082 68.033 66.6096 68.5285 67.3737 68.7569C68.1377 68.9854 68.8489 68.8604 68.9621 68.4777Z"
            fill="#6E9E23"
        />
        <path
            d="M95.9845 70.5369C96.7662 70.4262 97.7324 71.7941 97.9194 73.0562C98.1046 74.2951 97.6091 75.8837 96.8042 76.0092C96.0345 76.1281 95.1218 74.8751 94.8786 73.7538C94.5808 72.3772 95.1676 70.6543 95.9845 70.5369Z"
            fill="#F7C906"
        />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M170.598 28.8068L154.975 49.0252L151.299 66.4865L119.649 78.6288L132.459 81.1908V81.191L132.459 81.1908L132.459 83.9478L118.674 89.4619L118.674 89.4619L118.674 89.462L107.646 90.3811L95.2968 87.9716L78.2368 94.5166L71.8037 64.189L160.029 30.6448L170.598 28.8068ZM100.515 85.9698L113.048 88.3801L127.541 83.0124L114.627 80.5556L100.515 85.9698Z"
                fill="#130F2C"
            />
        </g>
        <path
            d="M11.1488 60.5129L0.580078 39.835L1.4991 59.1344L0.580078 76.5957L11.1488 60.5129Z"
            fill="#BE863C"
        />
        <path
            d="M78.6966 110.599L84.2108 86.7049L9.77026 60.0533L0.580078 76.5957L78.6966 110.599Z"
            fill="#FFC370"
        />
        <path
            d="M78.6966 63.7294L84.2108 86.7049L9.77026 60.0534L0.580078 39.835L78.6966 63.7294Z"
            fill="#E8A64D"
        />
        <path
            d="M0.580078 39.835L78.6966 63.7295L123.269 48.5657L170.598 28.8068L85.1298 16.4L0.580078 39.835Z"
            fill="#FECB85"
        />
        <path
            d="M114.079 94.0146L137.974 98.6097L126.946 99.5288L108.106 95.8528L114.079 94.0146Z"
            fill="#E8A64D"
        />
        <path
            d="M151.299 49.0251L137.514 54.5392L126.486 55.4581L151.299 46.2681L151.299 49.0251Z"
            fill="#D3A253"
        />
        <path
            d="M113.619 49.9441L137.514 54.5392L126.486 55.4582L107.646 51.7822L113.619 49.9441Z"
            fill="#FECB85"
        />
        <path
            d="M132.918 42.592L151.299 46.2681L151.299 49.0252L127.404 44.4796L132.918 42.592Z"
            fill="#FECB85"
        />
        <path
            d="M133.378 86.6624L151.759 90.3386L151.759 93.0956L127.864 88.55L133.378 86.6624Z"
            fill="#E8A64D"
        />
        <path
            d="M151.759 93.0955L137.974 98.6096L126.946 99.5285L151.759 90.3385L151.759 93.0955Z"
            fill="#D3A253"
        />
        <path
            d="M194.642 99.1344C197.175 95.8277 197.788 93.852 197.941 93.5317C198.046 93.3125 198.245 93.0163 198.453 92.9667C198.8 92.8828 199.33 93.4934 199.74 94.4699C199.478 94.6917 199.213 94.9235 198.951 95.1638C197.366 96.6201 196.128 98.1568 195.158 99.6243C194.984 99.4615 194.813 99.298 194.642 99.1344Z"
            fill="#603211"
        />
        <path
            d="M196.104 97.7289C196.116 97.7408 196.129 97.7527 196.137 97.7625C196.149 97.7716 196.161 97.7835 196.173 97.7925C196.686 98.2043 206.079 105.953 197.581 115.019C194.427 118.382 190.227 118.506 188.547 118.613C186.77 118.729 183.857 118.911 181.468 117.002C180.677 116.368 180.159 115.678 179.834 115.164C179.302 114.873 178.581 114.399 177.898 113.647C175.843 111.381 175.844 108.456 175.844 106.672C175.843 104.985 175.7 100.778 178.853 97.411C187.345 88.3444 195.657 97.2358 196.104 97.7289Z"
            fill="url(#paint0_linear_1469_5605)"
        />
        <path
            d="M210.08 99.9773C209.669 100.306 207.262 102.153 203.971 101.657C199.801 101.026 198.01 97.2123 197.885 96.9364C198.489 96.6137 201.298 95.1964 204.787 96.1581C207.998 97.0426 209.663 99.3578 210.08 99.9773Z"
            fill="#43883D"
        />
        <path
            d="M197.882 96.9371C201.949 97.9505 206.015 98.9639 210.078 99.978C209.696 99.4099 207.875 96.8419 204.353 96.05C201.097 95.3169 198.539 96.5851 197.882 96.9371Z"
            fill="#235B1D"
        />
        <path
            d="M177.574 9.1887C178.255 9.49626 182.211 11.2065 186.573 9.37978C192.099 7.07312 193.241 1.20632 193.32 0.782267C192.379 0.549271 188.012 -0.42467 183.544 2.101C179.424 4.43095 177.933 8.19148 177.574 9.1887Z"
            fill="#43883D"
        />
        <path
            d="M193.316 0.787289C188.069 3.58789 182.817 6.38846 177.57 9.18906C177.901 8.27572 179.518 4.1191 184.094 1.80778C188.33 -0.326456 192.291 0.530994 193.316 0.787289Z"
            fill="#235B1D"
        />
        <path
            d="M171.597 4.99512C172.866 4.99512 173.895 3.9665 173.895 2.6976C173.895 1.4287 172.866 0.400024 171.597 0.400024C170.328 0.400024 169.3 1.4287 169.3 2.6976C169.3 3.9665 170.328 4.99512 171.597 4.99512Z"
            fill="#43883D"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1469_5605"
                x1="192.11"
                y1="95.7551"
                x2="183.825"
                y2="119.065"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF1F1F" />
                <stop offset="1" stopColor="#F4D100" />
            </linearGradient>
        </defs>
    </svg>
);
