'use client';

import { LabsNotFound } from '@royalaholddelhaize/labs-ui/components/not-found/not-found';
import { useRouter } from 'next/navigation';

export default function NotFound() {
    const router = useRouter();

    return <LabsNotFound router={router} />;
}
